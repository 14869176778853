import { useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { SupportEmail } from '../SupportEmail'
import { submittedPageMessages } from './SubmittedPage.locales'

export function SubmittedPage() {
  const { formatMessage } = useLocales()
  return (
    <CustomerExperienceLayout variant="success">
      <Text data-test={CustomerPaymentsTestId.submittedHeader} textStyle="h1">
        {formatMessage(submittedPageMessages.header)}
      </Text>
      <Text data-test={CustomerPaymentsTestId.submittedDescription} fontSize="m">
        {formatMessage(submittedPageMessages.description, { email: <SupportEmail /> })}
      </Text>
    </CustomerExperienceLayout>
  )
}
