import { defineMessages } from '@sevenrooms/core/locales'

export const submittedPageMessages = defineMessages({
  header: {
    id: 'submittedHeader',
    defaultMessage: 'Submitted',
  },
  description: {
    id: 'submittedDescription',
    defaultMessage: 'Your payment information has been successfully submitted. If you have any questions please contact us at {email}',
  },
} as const)
