import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { appMessages } from '../../app.locales'
import { validateEmailsString } from '../../utils/validation'

export function useVenueEmailForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z.object({
        id: z.string(),
        label: z.string(),
        emails: z
          .string()
          .nullable()
          .default('')
          .refine(validateEmailsString, { message: formatMessage(appMessages.errorEmailsField) }),
      }),
    [formatMessage]
  )
}
export type EmailsCollectionStepForm = ZodSchema<typeof useEmailsCollectionStepForm>

export function useEmailsCollectionStepForm() {
  const venueEmailSchema = useVenueEmailForm()
  return useMemo(
    () =>
      z.object({
        venues: z.array(venueEmailSchema),
      }),
    [venueEmailSchema]
  )
}
