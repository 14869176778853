import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { useCustomerPaymentContext } from '../../hooks'
import { SupportEmail } from '../SupportEmail'
import { captureCompletedPageMessages } from './CaptureCompletedPage.locales'

export function CaptureCompletedPage() {
  const { formatMessage } = useLocales()
  const { venues } = useCustomerPaymentContext()
  const completedVenues = useMemo(() => venues.filter(venue => venue.completed), [venues])

  return (
    <CustomerExperienceLayout variant="success">
      <Text textStyle="h1">{formatMessage(captureCompletedPageMessages.header)}</Text>
      <Text fontSize="m">{formatMessage(captureCompletedPageMessages.description, { email: <SupportEmail /> })}</Text>
      <VStack spacing="s">
        <Text fontWeight="bold">{formatMessage(captureCompletedPageMessages.venuesTitle, { count: completedVenues.length })}</Text>
        <List>
          {completedVenues.map(venue => (
            <ListItem key={venue.id}>
              <Text>{venue.label}</Text>
            </ListItem>
          ))}
        </List>
      </VStack>
    </CustomerExperienceLayout>
  )
}
