import { useCallback } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { BACS } from '@sevenrooms/core/ui-kit/core/Adyen'
import { Button, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { paymentStepMessages } from './PaymentStep.locales'
import type { PaymentFlowStepProps } from './types'

export function PaymentOneTokenNonConsolidated({
  renderAdyenComponent,
  onSubmit,
  isValid,
  isLoading,
  displayControls,
  customerPaymentSessionId,
  paymentMethod,
  renderBACSControls,
  pennyTestLabel,
}: PaymentFlowStepProps) {
  const { formatMessage } = useLocales()
  const { push } = useNavigation()

  const onPaymentCompleted = useCallback(() => {
    push(routes.captureCustomerPayment.emailsCollectionStep, { query: { customerPaymentSessionId } })
  }, [customerPaymentSessionId, push])
  const isBACS = paymentMethod === BACS
  return (
    <>
      {displayControls && !isBACS && (
        <>
          <Text data-test={CustomerPaymentsTestId.paymentStepNonConsolidatedDescription} fontSize="m">
            {formatMessage(paymentStepMessages.paymentLabelOneVenue)}
          </Text>
          {pennyTestLabel}
        </>
      )}
      {renderAdyenComponent(onPaymentCompleted)}
      {displayControls && !isBACS && (
        <>
          <Button
            data-test={CustomerPaymentsTestId.paymentStepNonConsolidatedBackButton}
            variant="secondary"
            disabled={isLoading}
            href={routes.captureCustomerPayment.contractInfoStep.path}
          >
            {formatMessage(commonMessages.back)}
          </Button>
          <LoaderButton
            data-test={CustomerPaymentsTestId.paymentStepNonConsolidatedNextButton}
            disabled={!isValid}
            loading={isLoading}
            onClick={onSubmit}
          >
            {formatMessage(commonMessages.submit)}
          </LoaderButton>
        </>
      )}
      {isBACS &&
        renderBACSControls(onPaymentCompleted, () => {
          push(routes.captureCustomerPayment.contractInfoStep)
        })}
    </>
  )
}
