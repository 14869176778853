import { defineMessages } from '@sevenrooms/core/locales'

export const emailsCollectionStepMessages = defineMessages({
  header: {
    id: 'emailsCollectionStepHeader',
    defaultMessage: 'Optional: Add additional invoice recipients',
  },
  infoSecondLine: {
    id: 'emailsCollectionStepInfoSecondLine',
    defaultMessage:
      'Invoices will be sent to the primary billing contact we have on file. Please add any additional contacts separated by commas. If you do not wish to add additional invoice recipients, click “Next.”',
  },
  invoiceRecipientPlaceholder: {
    id: 'emailsCollectionStepPlaceholder',
    defaultMessage: 'email@email.com',
  },
} as const)
