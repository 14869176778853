import { z, type ZodSchema } from '@sevenrooms/core/form'
import { validateAccessCode } from '../../utils/validation'

export type ContractForm = ZodSchema<typeof contractFormSchema>

export const contractFormSchema = z.object({
  customerEmail: z.string().trim().email(),
  paymentAccessCode: z
    .string()
    .toUpperCase()
    .trim()
    .refine(val => validateAccessCode(val.toUpperCase())),
})
