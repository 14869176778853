import type { CustomerPaymentSessionData } from '@sevenrooms/core/domain'
import { createRequiredContext } from '@sevenrooms/core/ui-kit/hooks'

export const defaultCustomerPaymentSessionContext = {
  customerPaymentSessionId: '',
  isConsolidatedInvoice: false,
  isPayByParent: false,
  venues: [],
  selectedVenueIds: [],
}

export const [CustomerPaymentSessionProvider, useCustomerPaymentContext] = createRequiredContext<CustomerPaymentSessionData>()
