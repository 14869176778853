import { type ChangeEvent, type PropsWithChildren, useCallback, useMemo } from 'react'
import { useUpdateSelectedVenuesMutation } from '@sevenrooms/core/api'
import { useForm, FormProvider, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { LoaderButton, Button, Checkbox, CheckboxGroup, Label } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, Flex, HStack, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { routes } from '@sevenrooms/routes'
import { appMessages } from '../../app.locales'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { useCustomerPaymentContext } from '../../hooks'
import { SupportEmail } from '../SupportEmail'
import { venueSelectionStepMessages } from './VenuesSelectionStep.locales'
import { useVenuesSelectionStepFormSchema, type VenuesSelectionStepForm } from './VenuesSelectionStep.zod'

export interface VenuesSelectionProps {
  onDone: (formData: VenuesSelectionStepForm) => void
}

export function VenuesSelectionStep({ onDone }: PropsWithChildren<VenuesSelectionProps>) {
  const { formatMessage } = useLocales()
  const customerPaymentContext = useCustomerPaymentContext()
  const [updateSelectedVenues, { isLoading }] = useUpdateSelectedVenuesMutation()
  const venues = useMemo(() => customerPaymentContext.venues.filter(venue => !venue.completed), [customerPaymentContext])
  const completedVenues = useMemo(() => customerPaymentContext.venues.filter(venue => venue.completed), [customerPaymentContext])
  const choices = useMemo(() => venues.map(venue => ({ value: venue.id, label: venue.label })), [venues])

  const venuesSelectionStepFormSchema = useVenuesSelectionStepFormSchema()
  const { field, ...formMethods } = useForm(venuesSelectionStepFormSchema, {
    defaultValues: { venues: venues.map(item => item.id) },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods
  const selectedVenues = useWatch(field.prop('venues'))

  const onAllClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.prop('venues').set(e.currentTarget.checked ? venues.map(item => item.id) : [])
    },
    [field, venues]
  )

  const onClickNext = useCallback(
    async (formData: VenuesSelectionStepForm) => {
      try {
        await updateSelectedVenues({
          customerPaymentSessionId: customerPaymentContext.customerPaymentSessionId,
          selectedVenueIds: formData.venues,
        }).unwrap()
        onDone(formData)
      } catch (e) {
        notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
      }
    },
    [customerPaymentContext.customerPaymentSessionId, formatMessage, onDone, updateSelectedVenues]
  )

  return (
    <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
      <FormProvider {...formMethods}>
        <Text data-test={CustomerPaymentsTestId.venueSelectionHeader} textStyle="h1">
          {formatMessage(venueSelectionStepMessages.header)}
        </Text>
        <Label
          primary={formatMessage(venueSelectionStepMessages.infoFirstLine)}
          secondary={formatMessage(venueSelectionStepMessages.infoSecondLine, { email: <SupportEmail isSecondary /> })}
        />
        <VStack spacing="s">
          <HStack>
            <Checkbox
              data-test={CustomerPaymentsTestId.venueSelectionAllCheckbox}
              checked={selectedVenues.length === venues.length}
              onChange={onAllClick}
            >
              {formatMessage(venueSelectionStepMessages.allVenues)}
            </Checkbox>
            <Flex alignItems="center" justifyContent="end" flexGrow={1}>
              <Text>
                {formatMessage(venueSelectionStepMessages.selected, {
                  count: selectedVenues.length,
                  b: (chunks: never) => <Text fontWeight="bold">{chunks}</Text>,
                })}
              </Text>
            </Flex>
          </HStack>
          <DividerLine margin="none" color="borders" />
          <CheckboxGroup data-test={CustomerPaymentsTestId.venueSelectionIndividualGroup} field={field.prop('venues')} choices={choices} />
        </VStack>
        {completedVenues.length > 0 && (
          <VStack spacing="s">
            <Text fontWeight="bold">{formatMessage(venueSelectionStepMessages.completed)}</Text>
            <List>
              {completedVenues.map(venue => (
                <ListItem key={venue.id}>
                  <Text>{venue.label}</Text>
                </ListItem>
              ))}
            </List>
          </VStack>
        )}
        <VStack spacing="m" pt="s">
          <Button
            data-test={CustomerPaymentsTestId.venueSelectionBackButton}
            variant="secondary"
            disabled={isLoading}
            href={routes.captureCustomerPayment.contractInfoStep.path}
          >
            {formatMessage(commonMessages.back)}
          </Button>
          <LoaderButton
            data-test={CustomerPaymentsTestId.venueSelectionNextButton}
            loading={isLoading}
            disabled={!isValid}
            onClick={handleSubmit(onClickNext)}
          >
            {formatMessage(commonMessages.next)}
          </LoaderButton>
        </VStack>
      </FormProvider>
    </CustomerExperienceLayout>
  )
}
