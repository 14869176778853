import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { appMessages } from '../../app.locales'
import { validateEmailsString } from '../../utils/validation'

export type RecipientsForm = ZodSchema<typeof useRecipientsForm>

export function useRecipientsForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z.object({
        emails: z
          .string()
          .nullable()
          .default('')
          .refine(validateEmailsString, { message: formatMessage(appMessages.errorEmailsField) }),
      }),
    [formatMessage]
  )
}
