import { defineMessages } from '@sevenrooms/core/locales'

export const errorDialogMessages = defineMessages({
  title: {
    id: 'cx.paymentError.title',
    defaultMessage: 'An error has occurred',
  },
  body: {
    id: 'cx.paymentError.body',
    defaultMessage: 'Something went wrong, please check payment details and repeat or contact the support.',
  },
  emailErrorBody: {
    id: 'cx.paymentError.emailError.body',
    defaultMessage: 'The email address(es) you entered for Invoice Recipient is invalid. Please check the email address(es) and try again.',
  },
  adyenRefusedBody: {
    id: 'cx.paymentError.adyenRefused.body',
    defaultMessage:
      'The payment details you provided were not accepted. Please check your payment details and try again. If the error persists, please contact your financial institution to resolve the issue.',
  },
} as const)
