import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { preloadedState } from './preloadedState'
import { rootReducer, type RootState } from './reducers'

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: process.env.NODE_ENV === 'development',
})

export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector
