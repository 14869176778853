import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { venueSelectionStepMessages } from './VenuesSelectionStep.locales'

export type VenuesSelectionStepForm = ZodSchema<typeof useVenuesSelectionStepFormSchema>

export function useVenuesSelectionStepFormSchema() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z.object({
        venues: z.array(z.string()).min(1, {
          message: formatMessage(venueSelectionStepMessages.error),
        }),
      }),
    [formatMessage]
  )
}
