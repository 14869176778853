import { defineMessages } from '@sevenrooms/core/locales'

export const venueSelectionStepMessages = defineMessages({
  header: {
    id: 'venueSelectionStepHeader',
    defaultMessage: 'Which venues would you like to submit payment information for?',
  },
  infoFirstLine: {
    id: 'venueSelectionStepInfoFirstLine',
    defaultMessage: 'You will receive an individual invoice for each venue in this group. Provide a unique payment method for each venue.',
  },
  infoSecondLine: {
    id: 'venueSelectionStepInfoSecondLine',
    defaultMessage:
      'To change your billing and invoicing preferences, please reach out to {email} or your SevenRooms account manager for assistance.',
  },
  allVenues: {
    id: 'venueSelectionStepAllVenues',
    defaultMessage: 'All venues',
  },
  completed: {
    id: 'venueSelectionStepCompleted',
    defaultMessage: 'Payment capture completed',
  },
  refresh: {
    id: 'venueSelectionStepRefresh',
    defaultMessage: 'Refresh',
  },
  selected: {
    id: 'venueSelectionSelected',
    defaultMessage: `{count, plural,
      =0 {}
      other {<b>{count}</b> selected}
    }`,
  },
  error: {
    id: 'venueSelectionStepError',
    defaultMessage: 'Please, select at least one venue',
  },
} as const)
