import { defineMessages } from '@sevenrooms/core/locales'

export const noRecordsFoundPageMessages = defineMessages({
  header: {
    id: 'header',
    defaultMessage: 'No records found',
  },
  description: {
    id: 'description',
    defaultMessage:
      'The information you provided does not match any records we have on file. Please check if you inputted the entered ID from your contract or contact your SevenRooms account manager for assistance.',
  },
} as const)
