// access code pattern: {YY}+"PAC"+{AutoNum (10Digits)}+{MM}+{DD}
export const validateAccessCode = (accessCode: string) => String(accessCode).match(/^\d{2}(PAC)\d{10}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])$/)

export const validateEmail = (email: string) =>
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])?$/gi.test(
    email
  )

/**
 * Validates a comma delimited list of emails
 * @param emails - A comma delimited list of emails
 * @returns Whether all emails passed validation
 */
export function validateEmailsString(emails?: string | null) {
  return (
    emails == null ||
    emails === '' ||
    emails.split(',').every(email => {
      try {
        return validateEmail(email.trim())
      } catch (e) {
        return false
      }
    })
  )
}
