import { defineMessages } from '@sevenrooms/core/locales'

export const contractInfoStepMessages = defineMessages({
  header: {
    id: 'contractInfoStepPleaseProvide',
    defaultMessage: 'Please provide your SevenRooms contract info.',
  },
  description: {
    id: 'contractInfoStepPleaseProvideDescription',
    defaultMessage:
      'This information will be used as the payment method for your upcoming transaction with SevenRooms. You will be charged on the date\n' +
      ' specified in your contract.',
  },
  emailLabel: {
    id: 'contractInfoStepEmailLabel',
    defaultMessage: 'E-mail address',
  },
  emailDescription: {
    id: 'contractInfoStepEmailDescription',
    defaultMessage: 'Please provide your e-mail address',
  },
  accessCodeLabel: {
    id: 'contractInfoStepAccessCodeLabel',
    defaultMessage: 'SevenRooms Access Code',
  },
  accessCodeDescription: {
    id: 'contractInfoStepAccessCodeDescription',
    defaultMessage:
      'This is the 19-character ID found under the “Client Payment Information” section on your SevenRooms Services Order Form',
  },
} as const)
