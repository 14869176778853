import { combineReducers } from 'redux'
import { captureCustomerPaymentApi } from '@sevenrooms/core/api'

export interface RootState {
  adyen: {
    environment: string
    clientKey: string
  }
}

export const rootReducer = combineReducers({
  adyen: (state = {}) => state,
  [captureCustomerPaymentApi.reducerPath]: captureCustomerPaymentApi.reducer,
})
