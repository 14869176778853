export const CustomerPaymentsTestId = {
  contractInfoHeader: 'customer-payment-contract-info-header',
  contractInfoNextButton: 'customer-payment-contract-info-next',
  venueSelectionHeader: 'customer-payment-venue-selection-header',
  venueSelectionAllCheckbox: 'customer-payment-venue-selection-all-checkbox',
  venueSelectionIndividualGroup: 'customer-payment-venue-selection-individual-group',
  venueSelectionBackButton: 'customer-payment-venue-selection-back',
  venueSelectionNextButton: 'customer-payment-venue-selection-next',
  paymentStepHeader: 'customer-payment-payment-step-header',
  paymentStepPerVenueBACSlabel: 'customer-payment-per-venue-bacs-label',
  paymentStepPerVenueDescription: 'customer-payment-per-venue-description',
  pennyTestLabel: 'customer-payment-penny-test-label',
  paymentStepPerVenueName: 'customer-payment-per-venue-name',
  paymentStepPerVenueBackButton: 'customer-payment-per-venue-back',
  paymentStepPerVenueNextButton: 'customer-payment-per-venue-next',
  paymentStepOneTokenDescription: 'customer-payment-one-token-description',
  paymentStepOneTokenBackButton: 'customer-payment-one-token-back',
  paymentStepOneTokenNextButton: 'customer-payment-one-token-next',
  paymentStepNonConsolidatedDescription: 'customer-payment-non-consolidated-description',
  paymentStepNonConsolidatedBackButton: 'customer-payment-non-consolidated-back',
  paymentStepNonConsolidatedNextButton: 'customer-payment-non-consolidated-next',
  emailsCollectionStepDescription: 'customer-payment-emails-collection-description',
  emailsCollectionStepEmailsList: 'customer-payment-emails-collection-list',
  emailsCollectionStepBackButton: 'customer-payment-emails-collection-back',
  emailsCollectionStepNextButton: 'customer-payment-emails-collection-next',
  submittedHeader: 'customer-payment-submitted-header',
  submittedDescription: 'customer-payment-submitted-description',
  errorDialogDescription: 'customer-payment-error-dialog-description',
  errorDialogOkButton: 'customer-payment-error-dialog-ok',
  errorPageHeader: 'customer-payment-error-page-header',
  errorPageDescription: 'customer-payment-error-page-description',
  errorPageBackButton: 'customer-payment-error-page-back',
} as const
