import { type PropsWithChildren, useCallback } from 'react'
import { useCreateCustomerPaymentSessionMutation } from '@sevenrooms/core/api'
import type { CustomerPaymentSessionData } from '@sevenrooms/core/domain'
import { useForm, FormProvider } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { LoaderButton, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { routes } from '@sevenrooms/routes'
import { appMessages } from '../../app.locales'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { contractInfoStepMessages } from './ContractInfoStep.locales'
import { type ContractForm, contractFormSchema } from './ContractInfoStep.zod'

export interface ContractInfoProps {
  onDone: (data: CustomerPaymentSessionData) => void
}

export function ContractInfoStep({ onDone }: PropsWithChildren<ContractInfoProps>) {
  const { formatMessage } = useLocales()
  const [createCustomerPaymentSession, { isLoading }] = useCreateCustomerPaymentSessionMutation()
  const { field, ...formMethods } = useForm(contractFormSchema, { mode: 'onChange' })

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods

  const onClick = useCallback(
    async (contractForm: ContractForm) => {
      try {
        const data = await createCustomerPaymentSession(contractForm).unwrap()
        onDone(data)
      } catch (e) {
        notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
      }
    },
    [createCustomerPaymentSession, formatMessage, onDone]
  )

  return (
    <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
      <FormProvider {...formMethods}>
        <Text data-test={CustomerPaymentsTestId.contractInfoHeader} textStyle="h1">
          {formatMessage(contractInfoStepMessages.header)}
        </Text>
        <Text fontSize="m">{formatMessage(contractInfoStepMessages.description)}</Text>
        <Label
          primary={formatMessage(contractInfoStepMessages.emailLabel)}
          secondary={formatMessage(contractInfoStepMessages.emailDescription)}
        >
          <FormInput inputMode="email" field={field.prop('customerEmail')} autoFocus />
        </Label>
        <Label
          primary={formatMessage(contractInfoStepMessages.accessCodeLabel)}
          secondary={formatMessage(contractInfoStepMessages.accessCodeDescription)}
        >
          <FormInput field={field.prop('paymentAccessCode')} />
        </Label>
        <VStack pt="s">
          <LoaderButton
            data-test={CustomerPaymentsTestId.contractInfoNextButton}
            loading={isLoading}
            disabled={!isValid}
            onClick={handleSubmit(onClick)}
          >
            {formatMessage(commonMessages.next)}
          </LoaderButton>
        </VStack>
      </FormProvider>
    </CustomerExperienceLayout>
  )
}
