import { useLocales } from '@sevenrooms/core/locales'
import { Link } from '@sevenrooms/core/navigation'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { supportEmailMessages } from './SupportEmail.locales'

interface SupportEmailProps {
  isSecondary?: boolean
}

export function SupportEmail({ isSecondary }: SupportEmailProps) {
  const { formatMessage } = useLocales()
  return (
    <Link to={`mailto: ${formatMessage(supportEmailMessages.email)}`} isExternal>
      <Text color={isSecondary ? 'secondaryFont' : 'primaryFont'}>{formatMessage(supportEmailMessages.email)}</Text>
    </Link>
  )
}
