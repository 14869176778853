import { defineMessages } from '@sevenrooms/core/locales'

export const paymentStepMessages = defineMessages({
  header: {
    id: 'paymentStepHeader',
    defaultMessage: 'Payment information',
  },
  paymentLabelOneVenue: {
    id: 'paymentStepPaymentLabelOneVenue',
    defaultMessage: 'The information provided will be used for payment of SevenRooms services',
  },
  paymentConsolidatedInvoice: {
    id: 'paymentStepPaymentConsolidatedInvoice',
    defaultMessage:
      'You will receive one invoice for all venues under this group. Please provide payment method which should be used for all charges',
  },
  paymentLabelPerVenue: {
    id: 'paymentStepPaymentLabelPerVenue',
    defaultMessage:
      'The e-mail address used on the contract has been automatically applied. Please specify a different or any additional invoice recipients.',
  },
  pennyLabelForPayment: {
    id: 'pennyLabelForPayment',
    defaultMessage: 'Note: An authorization charge of $0.01 will be processed and automatically refunded.',
  },
  paymentConsolidatedInvoiceDetails: {
    id: 'paymentStepPaymentConsolidatedInvoiceDetails',
    defaultMessage:
      'To change your billing and invoicing preferences, please reach out to {email} or your SevenRooms account manager for assistance. ',
  },
  invoiceRecipient: {
    id: 'paymentStepInvoiceRecipient',
    defaultMessage: 'Additional invoice recipient',
  },
  invoiceRecipientLabel: {
    id: 'paymentStepInvoiceRecipientLabel',
    defaultMessage:
      'Invoice will be sent to the primary billing contact we have on file. Please add any additional contacts separated by commas.',
  },
  invoiceRecipientPerVenue: {
    id: 'paymentStepInvoiceRecipientPerVenue',
    defaultMessage: 'Invoice recipient',
  },
  invoiceRecipientLabelPerVenue: {
    id: 'paymentStepInvoiceRecipientLabelPerVenue',
    defaultMessage:
      'Invoices will be sent to the primary billing contact we have on file. Please add any additional contacts separated by commas.',
  },
  invoiceRecipientPlaceholderPerVenue: {
    id: 'paymentStepInvoiceRecipientPlaceholderPerVenue',
    defaultMessage: 'email@email.com',
  },
  confirmDownloadedBACSdoc: {
    id: 'confirmDownloadedBACSdoc',
    defaultMessage: 'I confirm that I’ve downloaded the document, and I am ready to go to the next step.',
  },
} as const)
