import { defineMessages } from '@sevenrooms/core/locales'

export const appMessages = defineMessages({
  error: {
    id: 'error',
    defaultMessage: 'Error',
  },
  errorDescription: {
    id: 'errorDescription',
    defaultMessage: 'Something went wrong',
  },
  errorEmailsField: {
    id: 'errorEmailsField',
    defaultMessage: 'Please provide one or more comma-separated email addresses',
  },
  genericServerError: {
    id: 'genericServerError',
    defaultMessage: 'Server has responded with an error',
  },
} as const)
