import * as FS from '@fullstory/browser'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useState } from 'react'
import { Provider } from 'react-redux'
import { useGetCustomerPaymentSessionQuery } from '@sevenrooms/core/api'
import type { CustomerPaymentSessionData } from '@sevenrooms/core/domain'
import { Route, Router, Switch, useNavigation, Redirect, ConditionalRoute } from '@sevenrooms/core/navigation'
import { Box, Root, NotificationContainer, Loader } from '@sevenrooms/core/ui-kit/layout'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { routes } from '@sevenrooms/routes'
import {
  ContractInfoStep,
  PaymentStep,
  SubmittedPage,
  ErrorPage,
  PaymentFinal,
  VenuesSelectionStep,
  EmailsCollectionStep,
  NoRecordsFoundPage,
  CaptureCompletedPage,
} from './components'
import { CustomerPaymentSessionProvider, defaultCustomerPaymentSessionContext } from './hooks'
import { store } from './store'
import type { VenuesSelectionStepForm } from './components/VenuesSelectionStep/VenuesSelectionStep.zod'

const setUpFullStoryData = (customerPaymentSessionId: string) => {
  FS.identify(customerPaymentSessionId)
}

function Widget() {
  const params = new URLSearchParams(window.location.search)
  const customerPaymentSessionIdQuery = params.get('customerPaymentSessionId')
  const [customerPaymentContext, setCustomerPaymentContext] = useState<CustomerPaymentSessionData>(defaultCustomerPaymentSessionContext)
  const { push } = useNavigation()

  const onContractInfoStepDone = useCallback(
    (data: CustomerPaymentSessionData) => {
      setCustomerPaymentContext(data)
      setUpFullStoryData(data.customerPaymentSessionId)
      if (data.customerPaymentSessionId == null) {
        push(routes.captureCustomerPayment.noRecordsFound)
      } else if (data.venues.every(venue => venue.completed)) {
        push(routes.captureCustomerPayment.captureCompleted)
      } else if (data.isPayByParent) {
        push(routes.captureCustomerPayment.paymentStep, {
          // eslint-disable-next-line
          // @ts-ignore
          query: { customerPaymentSessionId: data.customerPaymentSessionId },
        })
      } else {
        push(routes.captureCustomerPayment.venuesSelectionStep, {
          query: { customerPaymentSessionId: data.customerPaymentSessionId },
        })
      }
    },
    [push]
  )

  const { data: customerPaymentSessionData, isError } = useGetCustomerPaymentSessionQuery(customerPaymentSessionIdQuery ?? skipToken)
  if (isError) {
    push(routes.captureCustomerPayment.error)
  }

  if (customerPaymentSessionData && !customerPaymentContext.customerPaymentSessionId) {
    setCustomerPaymentContext(customerPaymentSessionData)
    setUpFullStoryData(customerPaymentSessionData.customerPaymentSessionId)
  }

  const onVenuesSelectionDone = useCallback(
    ({ venues: selectedVenueIds }: VenuesSelectionStepForm) => {
      setCustomerPaymentContext({ ...customerPaymentContext, selectedVenueIds })
      setUpFullStoryData(customerPaymentContext.customerPaymentSessionId)
      push(routes.captureCustomerPayment.paymentStep, {
        query: {
          customerPaymentSessionId: customerPaymentContext.customerPaymentSessionId,
          sfdcVenueId: selectedVenueIds[0] as string,
        },
      })
    },
    [customerPaymentContext, push]
  )

  if (customerPaymentSessionIdQuery && !customerPaymentContext.customerPaymentSessionId) {
    return (
      <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
        <Box pt="xxl" pb="xxl">
          <Loader size="m" />
        </Box>
      </CustomerExperienceLayout>
    )
  }

  return (
    <CustomerPaymentSessionProvider value={customerPaymentContext}>
      <Switch>
        <Route
          path={routes.captureCustomerPayment.contractInfoStep.path}
          render={() => <ContractInfoStep onDone={onContractInfoStepDone} />}
        />
        <Route
          path={routes.captureCustomerPayment.venuesSelectionStep.path}
          render={() => (
            <ConditionalRoute
              condition={customerPaymentContext.customerPaymentSessionId !== ''}
              redirectTo={routes.captureCustomerPayment.contractInfoStep.path}
            >
              <VenuesSelectionStep onDone={onVenuesSelectionDone} />
            </ConditionalRoute>
          )}
        />
        <Route
          path={routes.captureCustomerPayment.paymentStep.path}
          render={() => (
            <ConditionalRoute
              condition={customerPaymentContext.customerPaymentSessionId !== ''}
              redirectTo={routes.captureCustomerPayment.contractInfoStep.path}
            >
              <PaymentStep />
            </ConditionalRoute>
          )}
        />
        <Route
          path={routes.captureCustomerPayment.emailsCollectionStep.path}
          render={() => (
            <ConditionalRoute
              condition={customerPaymentContext.customerPaymentSessionId !== ''}
              redirectTo={routes.captureCustomerPayment.contractInfoStep.path}
            >
              <EmailsCollectionStep />
            </ConditionalRoute>
          )}
        />
        <Route path={routes.captureCustomerPayment.captureCompleted.path} render={() => <CaptureCompletedPage />} />
        <Route path={routes.captureCustomerPayment.paymentFinal.path} render={() => <PaymentFinal />} />
        <Route path={routes.captureCustomerPayment.submitted.path} render={() => <SubmittedPage />} />
        <Route path={routes.captureCustomerPayment.error.path} render={() => <ErrorPage />} />
        <Route path={routes.captureCustomerPayment.noRecordsFound.path} render={() => <NoRecordsFoundPage />} />
        <Route path={routes.captureCustomerPayment.path} exact>
          <Redirect to={routes.captureCustomerPayment.contractInfoStep.path} />
        </Route>
      </Switch>
    </CustomerPaymentSessionProvider>
  )
}

export function App() {
  return (
    <Provider store={store}>
      <Router>
        <Root theme="vx">
          <Widget />
          <NotificationContainer limit={3} />
        </Root>
      </Router>
    </Provider>
  )
}
