import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Text, Par } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { routes } from '@sevenrooms/routes'
import { SupportEmail } from '../SupportEmail'
import { noRecordsFoundPageMessages } from './NoRecordsFoundPage.locales'

export function NoRecordsFoundPage() {
  const { formatMessage } = useLocales()
  return (
    <CustomerExperienceLayout variant="error">
      <Text textStyle="h1">{formatMessage(noRecordsFoundPageMessages.header)}</Text>
      <Text fontSize="m">
        {formatMessage(noRecordsFoundPageMessages.description, {
          p: (chunk: never) => <Par>{chunk}</Par>,
          email: <SupportEmail />,
        })}
      </Text>
      <Button variant="primary" href={routes.captureCustomerPayment.contractInfoStep.path} data-test="retry-button">
        {formatMessage(commonMessages.retry)}
      </Button>
    </CustomerExperienceLayout>
  )
}
