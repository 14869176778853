import { defineMessages } from '@sevenrooms/core/locales'

export const captureCompletedPageMessages = defineMessages({
  header: {
    id: 'header',
    defaultMessage: 'Payment details submitted',
  },
  description: {
    id: 'description',
    defaultMessage:
      'Payment details for all venues on file have been submitted. Please reach out to {email} or your SevenRooms account manager to make any changes.',
  },
  venuesTitle: {
    id: 'venuesTitle',
    defaultMessage: `{count, plural,
      one {Venue}
      other {Venues}
    }`,
  },
} as const)
