import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { errorDialogMessages } from './ErrorDialog.locales'

interface ErrorDialogProps {
  onClose: () => void
  message: string
}

export function ErrorDialog({ onClose, message }: ErrorDialogProps) {
  const { formatMessage } = useLocales()
  return (
    <Modal ariaLabel="main">
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(errorDialogMessages.title)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text data-test={CustomerPaymentsTestId.errorDialogDescription}>{message}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button data-test={CustomerPaymentsTestId.errorDialogOkButton} variant="primary" onClick={onClose}>
            {formatMessage(commonMessages.ok)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
